<template>
  <loading
    :active="isLoadingModel"
    :can-cancel="true"
    color="#ffc107"
    :is-full-page="fullPage"
  ></loading>

  <div class="grid">
    <div class="col-12">
      <div class="card">
        <Toast />
        <Toolbar class="mb-4">
          <template v-slot:start>
            <div class="my-2">
              <Button
                label="New"
                icon="pi pi-plus"
                class="p-button-success mr-2"
                @click="openNew"
              />
              <!-- <Button
                label="Delete"
                icon="pi pi-trash"
                class="p-button-danger"
                @click="confirmDeleteSelected"
                :disabled="!selectedProducts || !selectedProducts.length"
              /> -->
              
            </div>
          </template>

          <template v-slot:end>
            <!-- <FileUpload
              mode="basic"
              accept="application/pdf,image/jpeg,image/png"
              :maxFileSize="1000000"
              label="Import"
              chooseLabel="Import"
              class="mr-2 inline-block"
            /> -->
            <Button
              label="Export"
              icon="pi pi-upload"
              class="p-button-help"
              @click="exportCSV($event)"
            />
          </template>
        </Toolbar>

        <DataTable
          :loading="loading"
          ref="dt"
          :lazy="true"
          :totalRecords="totalRecords"
          :paginator="true"
          :value="products"
          v-model:selection="selectedProducts"
          :dataKey="columns[0]"
          :rows="limit"
          :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="[5, 10, 25, totalRecords]"
          currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll"
          style="font-size: 12px"
          :rowHover="true" showGridlines
        >
          <template #header>
            <div
              class="
                flex flex-column
                md:flex-row md:justify-content-between md:align-items-center
              "
            >
              <h5 class="m-0"><b>Mock Test</b></h5>
               <Button icon="pi pi-refresh" @click="get_list()" />
            </div>
          </template>

            <Column header="Sr No" style="min-width: 5rem;text-align: center;">
            <template #body="{ index}">
            <span v-if="page_no==1">{{ +index+ +1 }}</span>
              <span v-else>{{(+index+1)+limit*(page_no-1)}}</span>
            </template>
          </Column>

          <Column header="Year" style="min-width: 3rem" :field="columns[0]">
            <template #body="{ data }">
              {{ data.year }}
            </template>
          </Column>
          <Column
            header="Test Date"
            style="min-width: 5rem"
            :field="columns[1]"
          >
            <template #body="{ data }">
              {{ data.tesT_DATE }}
            </template>
          </Column>
          <Column
            header="Test Topic"
            style="min-width: 5rem"
            :field="columns[6]"
          >
            <template #body="{ data }">
              {{ data.tesT_TOPIC }}
            </template>
          </Column>
          <Column header="Question" :field="columns[2]">
            <template #body="{ data }">
              <span
                v-if="
                  data.questions == '' ||
                  data.questions == null ||
                  data.questions == undefined
                "
                >-</span
              >

              <span v-else
                ><Button
                  label="View"
                  class="p-button-outlined"
                  @click="view_video(data.questions)"
              /></span>
            </template>
          </Column>
          <Column header="Answer" :field="columns[4]">
            <template #body="{ data }">
              <span
                v-if="
                  data.answers == '' ||
                  data.answers == null ||
                  data.answers == undefined
                "
                >-</span
              >

              <span v-else
                ><Button
                  label="View"
                  class="p-button-outlined"
                  @click="view_video(data.answers)"
              /></span>
            </template>
          </Column>

          <Column header="Edit" headerStyle="min-width:5rem;" style="text-align:center">
            <template #body="slotProps">
              <Button
                icon="pi pi-pencil"
                class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)"
              />
              <!-- <Button
                icon="pi pi-trash"
                class="p-button-rounded p-button-warning mt-2"
                @click="confirmDeleteProduct(slotProps.data)"
              /> -->
            </template>
          </Column>
        </DataTable>

        <Sidebar
          v-model:visible="productDialog"
          :baseZIndex="1000"
          position="full"
        >
          <div class="col-12">
            <div class="card">
              <h5>Mock Test</h5>
              <hr />
              <div class="p-fluid formgrid grid">
                <div class="field col-12 md:col-2">
                  <label for="year">Year</label>
                  <Dropdown
                    style="width: 100%"
                    id="status"
                    v-model="submissionstatus"
                    :options="submissionItems"
                    :value="submissionItems.value"
                    optionLabel="name"
                    placeholder="Select Year"
                    :class="{ 'p-invalid': submitted && !selectstatus }"
                    required="true"
                  ></Dropdown>
                </div>

                <div class="field col-12 md:col-2">
                  <label for="year">Test Date</label>
                  <Calendar
                    style="width: 100%"
                    v-model="product.tesT_DATE"
                    :showIcon="true"
                    dateFormat="d-MM-yy"
                    hourFormat="12"
                    :numberOfMonths="2"
                  />
                </div>

                <div class="field col-12 md:col-8">
                  <label for="year">Test Topic</label>

                  <Textarea
                    id="exaM_YEAR"
                    v-model.trim="product.tesT_TOPIC"
                    required="true"
                    autofocus
                    rows="3"
                    :class="{ 'p-invalid': submitted && !product.tesT_TOPIC }"
                  />
                </div>

                <div class="field col-12 md:col-3">
                  <label for="year">Question</label>

                  <FileUpload
                    mode="basic"
                    id="file_attachmentQ"
                    ref="file_attachmentQ"
                    name="demo[]"
                    accept="application/pdf,image/jpeg,image/png"
                    :maxFileSize="1000000"
                    @change="handleFileUploadQ"
                    style="width: 100%"
                  />
                </div>

                <div class="field col-12 md:col-3">
                  <label for="year">Answer</label>

                  <FileUpload
                    mode="basic"
                    id="file_attachmentA"
                    ref="file_attachmentA"
                    name="demo[]"
                    accept="application/pdf,image/jpeg,image/png"
                    :maxFileSize="1000000"
                    @change="handleFileUploadA"
                    style="width: 100%"
                  />
                </div>
                <div class="field col-12 md:col-6"></div>
                <div class="field col-12 md:col-12"><hr /></div>

                <div class="field col-12 md:col-3"></div>
                <div class="field col-12 md:col-3">
                  <Button
                    v-if="product.mocK_ID"
                    label="UPDATE"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="Addmocktest"
                  />
                  <Button
                    v-else
                    label="Create"
                    icon="pi pi-check"
                    class="p-button-success mr-1 mb-1"
                    @click="Addmocktest"
                  />
                </div>
                <div class="field col-12 md:col-3">
                  <Button
                    label="Cancel"
                    icon="pi pi-times"
                    class="p-button-danger mr-1 mb-1"
                    @click="productDialog = false"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="col-12">
            <div class="card">
              <DataTable
                :value="std_list"
                style="font-size: 12px"
                :paginator="true"
                class="p-datatable-gridlines"
                :rows="10"
                dataKey="id"
                :rowHover="true"
                v-model:filters="filters1"
                filterDisplay="menu"
                :loading="loading1"
                
                responsiveLayout="scroll"
              >
                <template #header>
                  <div
                    class="
                      flex flex-column
                      md:flex-row
                      md:justify-content-between
                      md:align-items-center
                    "
                    style="color: brown"
                  >
                    <h5 class="m-0">Student Answer</h5>
                  </div>
                </template>

                <Column
                  header="Student Name"
                  :field="columns[6]"
                  style="min-width: 10rem"
                >
                  <template #body="{ data }">
                    {{ data.studentName }}
                  </template>
                </Column>
                <Column
                  header="Student Answer"
                  :field="columns[6]"
                  style="min-width: 10rem"
                >
                  <template #body="{ data }">
                    <span
                      v-if="
                        data.anseer == '' ||
                        data.anseer == null ||
                        data.anseer == undefined
                      "
                      >-</span
                    >

                    <span v-else
                      ><Button
                        label="View Answer"
                        class="p-button-outlined"
                        @click="view_video(data.anseer)"
                    /></span>
                  </template>
                </Column>
                <Column
                  header="Remark"
                  :field="columns[6]"
                  style="min-width: 10rem"
                >
                  <template #body="{ data }">
                    <span
                      v-if="
                        data.remark == '' ||
                        data.remark == null ||
                        data.remark == undefined
                      "
                      >-</span
                    >

                    <span v-else>{{ data.remark }}</span>
                  </template>
                </Column>
              </DataTable>
            </div>
          </div>
        </Sidebar>

        <Dialog
          v-model:visible="deleteProductDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete <b>{{ product.name }}</b
              >?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteVideo"
            />
          </template>
        </Dialog>

        <Dialog
          v-model:visible="deleteProductsDialog"
          :style="{ width: '450px' }"
          header="Confirm"
          :modal="true"
        >
          <div class="flex align-items-center justify-content-center">
            <i
              class="pi pi-exclamation-triangle mr-3"
              style="font-size: 2rem"
            />
            <span v-if="product"
              >Are you sure you want to delete the selected products?</span
            >
          </div>
          <template #footer>
            <Button
              label="No"
              icon="pi pi-times"
              class="p-button-text"
              @click="deleteProductsDialog = false"
            />
            <Button
              label="Yes"
              icon="pi pi-check"
              class="p-button-text"
              @click="deleteSelectedProducts"
            />
          </template>
        </Dialog>
      </div>
    </div>
  </div>
</template>

<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";
//import FormNewsMaster from "@/components/FormNewsMaster";
//import * as Survey from "survey-vue";

// import axios from "axios";
import apis from "@/apis";
import axios from "axios";

export default {
  data() {
    return {
      page_no:1,
      submissionItems: [],
      submissionstatus: { name: "", value: "" },
      current_year: this.getCurrentFinancialYear(),
      isLoading: false,
      isLoadingModel: false,
      loading: false,
      fullPage: true,
      file_attachmentA: "",
      file_attachmentQ: "",
      dropdownItems: [
        { name: "Motivational", value: "Motivational" },
        { name: "Interview", value: "Interview" },
      ],
      dropdownItems2: [
        { name: "Active", value: "Active" },
        { name: "InActive", value: "InActive" },
        { name: "Deleted", value: "Deleted" },
      ],
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      std_list: null,
      selectedProducts: null,
      filters: {},
      offset: 1,
      selectstatus: { name: "Active", value: "Active" },
      selectstatus2: { name: "Motivational", value: "Motivational" },
      limit: 10,
      columns: [],
      head: [
        "Data Usages",
        "Network Connectivity",
        "Students",
        "Teachers",
        "Student Registration",
        "News Master",
        "Government Panel",
        "Feedback Master",
        "User Queries",
        "Schemes Master",
        "Notice Master",
        "Subject Master",
        "Teacher Schedule Master",
        "Teacher Schedule DE Master",
        "Query Types Master",
        "Sub Query Types Master",
        "Admin Users",
        "Application Roles",
        "Departments",
        "Cast Master",
        "Year Master",
        "District Master",
        "Student Attendance",
        "Student Document",
        "Teachers",
        "Radical ids",
        "Subject Topics",
        "Query Comments",
        "Student Submit Answers",
        "Mock Tests",
        "Mock Test Papers",
        "Mock Test Results",
        "Video",
        "Syllabus",
        "Course",
      ],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      statuses: [
        { label: "INSTOCK", value: "instock" },
        { label: "LOWSTOCK", value: "lowstock" },
        { label: "OUTOFSTOCK", value: "outofstock" },
      ],
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
    this.initFilters();
    this.get_submission_dropdown();
  },
  async mounted() {
    // this.productService.getProducts().then(data => this.products = data);
    this.id = this.$route.params.id;

    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
    //alert(this.id);
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  methods: {
    get_submission_dropdown: function () {
      var data = {
        //  "TEACHER_ID":parseInt(localStorage.getItem("id"))
        Limit: 100,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.yearlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);

        var info = response.data;
        if (info) {
          this.submissionItems = [];
          for (var i = 0; i < info.length; i++) {
            this.submissionItems.push({
              name: info[i].exaM_YEAR,
              value: info[i].enrollmenT_YEAR,
            });
          }
        }
      });
    },
    getCurrentFinancialYear() {
      var fiscalyear = "";
      var today = new Date();
      if (today.getMonth() + 1 <= 3) {
        fiscalyear = today.getFullYear() - 1 + "-" + today.getFullYear();
      } else {
        fiscalyear = today.getFullYear() + "-" + (today.getFullYear() + 1);
      }
      return fiscalyear;
    },

    view_video(video) {
      // alert(video)
      window.open(video, "_blank");
    },

    //a simple date formatting function
    dateFormat(inputDate, info) {},
    handleFileUploadA() {
      this.file_attachmentA = this.$refs.file_attachmentA.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    handleFileUploadQ() {
      this.file_attachmentQ = this.$refs.file_attachmentQ.files[0];

      //alert(this.$refs.file_attachment.files[0].name);
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        count: true,
      };
      this.loading = true;
      var promise = apis.mocktestlistmaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data;
        //alert(this.totalRecords);
      });

      console.log(data);
    },
    get_list: function () {
      var data = {
        Limit: this.limit,
        page_no: this.page_no,
        count: false,
      };
      this.loading = true;
      var promise = apis.mocktestlistmaster(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });

      console.log(data);
    },
    formatCurrency(value) {
      if (value)
        return value.toLocaleString("en-US", {
          style: "currency",
          currency: "USD",
        });
      return;
    },
    openNew() {
      this.product = {};
      this.submitted = false;
      this.productDialog = true;
    },
    hideDialog() {
      this.productDialog = false;
      this.submitted = false;
    },
    async Addmocktest() {
      this.submitted = true;

      if(!this.submissionstatus.value)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Year",
            life: 3000,
          });
          return false;
    }

    if(!this.product.tesT_DATE)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Test Date",
            life: 3000,
          });
          return false;
    }
    if(!this.product.tesT_TOPIC)
    {
    this.$toast.add({
            severity: "error",
            summary: "error",
            detail: "Please Enter Test Topic",
            life: 3000,
          });
          return false;
    }

      //edit
      if (this.product.mocK_ID) {
        var from = new Date(this.product.tesT_DATE);
        var f_date =
          from.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          from.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          from.toLocaleDateString("en-US", { day: "2-digit" });

        //------------------------------------------------------

        if (this.file_attachmentQ.name) {
          var d = new Date();
          var file_name =
            d.getTime() +
            "_" +
            d.getMilliseconds() +
            "_" +
            this.file_attachmentQ.name;
        }
        if (this.file_attachmentA.name) {
          var d2 = new Date();
          var file_name2 =
            d2.getTime() +
            "_" +
            d2.getMilliseconds() +
            "_" +
            this.file_attachmentA.name;
        }

        var data = {
          Mock_id: this.product.mocK_ID,
          tesT_DATE: f_date,
          tesT_TOPIC: this.product.tesT_TOPIC,
          questions: file_name,
          answers: file_name2,
          year: this.submissionstatus.value,
        };
        this.isLoadingModel = true;
        var promise = apis.mocktestedit(data);
        promise
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              this.$swal(responseapi.data.message);
              this.isLoadingModel = false;
              this.productDialog = false;
              this.get_list();
              this.get_count();

              if (this.file_attachmentA.name) {
                var file_data = {
                  filePath: "pt_assign/" + file_name,
                  contentType: this.file_attachmentA.type,
                };

                var promise5 = apis.uploadFile(file_data);
                promise5.then((response) => {
                  axios
                    .put(response.data.url, this.file_attachmentA, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": this.file_attachmentA.type,
                      },
                    })
                    .then((response) => {});
                });
              }
              if (this.file_attachmentQ.name) {
                var file_data2 = {
                  filePath: "pt_assign/" + file_name2,
                  contentType: this.file_attachmentQ.type,
                };

                var promise6 = apis.uploadFile(file_data2);
                promise6.then((response) => {
                  axios
                    .put(response.data.url, this.file_attachmentQ, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": this.file_attachmentQ.type,
                      },
                    })
                    .then((response) => {});
                });
              }
            }
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }
      //add-----------------------------------------------------------------------------------------------------------------
      else {
        var from2 = new Date(this.product.tesT_DATE);
        var t_date =
          from2.toLocaleDateString("en-US", { year: "numeric" }) +
          "-" +
          from2.toLocaleDateString("en-US", { month: "2-digit" }) +
          "-" +
          from2.toLocaleDateString("en-US", { day: "2-digit" });
        //---------------------------------------------------------
        if (this.file_attachmentQ.name) {
          var d3 = new Date();
          var file_name3 =
            d3.getTime() +
            "_" +
            d3.getMilliseconds() +
            "_" +
            this.file_attachmentQ.name;
        }
        if (this.file_attachmentA.name) {
          var d4 = new Date();
          var file_name4 =
            d4.getTime() +
            "_" +
            d4.getMilliseconds() +
            "_" +
            this.file_attachmentA.name;
        }

        var data1 = {
          tesT_DATE: t_date,
          tesT_TOPIC: this.product.tesT_TOPIC,
          questions: file_name3,
          answers: file_name4,
          year: this.submissionstatus.value,
          created_by: localStorage.getItem("full_name"),
        };
        this.isLoadingModel = true;
        var promises = apis.mocktestadd(data1);
        promises
          .then((responseapi) => {
            if (responseapi.data.status == true) {
              this.$swal(responseapi.data.message);
              this.isLoadingModel = false;
              this.productDialog = false;
              this.get_list();
              this.get_count();

              if (this.file_attachmentA.name) {
                var file_data4 = {
                  filePath: "pt_assign/" + file_name4,
                  contentType: this.file_attachmentA.type,
                };

                var promise5 = apis.uploadFile(file_data4);
                promise5.then((response) => {
                  axios
                    .put(response.data.url, this.file_attachmentA, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": this.file_attachmentA.type,
                      },
                    })
                    .then((response) => {});
                });
              }
              if (this.file_attachmentQ.name) {
                var file_data3 = {
                  filePath: "pt_assign/" + file_name3,
                  contentType: this.file_attachmentQ.type,
                };

                var promise6 = apis.uploadFile(file_data3);
                promise6.then((response) => {
                  axios
                    .put(response.data.url, this.file_attachmentQ, {
                      headers: {
                        "Access-Control-Allow-Origin": "*",
                        "Content-Type": this.file_attachmentQ.type,
                      },
                    })
                    .then((response) => {});
                });
              }
            }
          })
          .catch((error) => {
            //console.log(error);
            this.isLoadingModel = false;
            this.productDialog = false;

            this.$swal.fire(error.response.data);
            this.get_list();
            this.get_count();
          });
      }

      this.selectstatus = { name: "", value: "" };
      this.selectstatus2 = { name: "", value: "" };
    },
    saveProduct() {
      this.submitted = true;
      if (this.product.name.trim()) {
        if (this.product.id) {
          this.product.inventoryStatus = this.product.inventoryStatus.value
            ? this.product.inventoryStatus.value
            : this.product.inventoryStatus;
          this.products[this.findIndexById(this.product.id)] = this.product;
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Updated",
            life: 3000,
          });
        } else {
          this.product.id = this.createId();
          this.product.code = this.createId();
          this.product.image = "product-placeholder.svg";
          this.product.inventoryStatus = this.product.inventoryStatus
            ? this.product.inventoryStatus.value
            : "INSTOCK";
          this.products.push(this.product);
          this.$toast.add({
            severity: "success",
            summary: "Successful",
            detail: "Product Created",
            life: 3000,
          });
        }
        this.productDialog = false;
        this.product = {};
      }
    },
    get_student_list(listdata) {
      var data = {
        Mock_id: listdata.mocK_ID,
      };
      this.loading = true;
      var promise = apis.mockteststudentlist(data, this.id);
      promise.then((response) => {
        this.loading = false;
        this.std_list = response.data;
      });
    },
    async editProduct(product) {
      this.file_attachment = "";
      this.product = { ...product };

      this.submissionstatus.value = this.product.year;

      await this.get_student_list(this.product);

      this.productDialog = true;
    },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deleteProduct() {
      this.products = this.products.filter((val) => val.id !== this.product.id);
      this.deleteProductDialog = false;
      this.product = {};
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Product Deleted",
        life: 3000,
      });
    },

    deleteVideo() {
      if (this.product.id) {
        var data = {
          Id: this.product.id,
        };
        this.isLoadingModel = true;
        var promise = apis.deletebatchmaster(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.status == 200) {
            // this.hideDialog1();
            this.deleteProductDialog = false;
            this.$swal(responseapi.data);
            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to Delete Video");
          }
        });
      }
    },
    findIndexById(id) {
      let index = -1;
      for (let i = 0; i < this.products.length; i++) {
        if (this.products[i].id === id) {
          index = i;
          break;
        }
      }
      return index;
    },
    createId() {
      let id = "";
      var chars =
        "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
      for (var i = 0; i < 5; i++) {
        id += chars.charAt(Math.floor(Math.random() * chars.length));
      }
      return id;
    },
    exportCSV() {
      this.$refs.dt.exportCSV();
    },
    confirmDeleteSelected() {
      this.deleteProductsDialog = true;
    },
    deleteSelectedProducts() {
      this.products = this.products.filter(
        (val) => !this.selectedProducts.includes(val)
      );
      this.deleteProductsDialog = false;
      this.selectedProducts = null;
      this.$toast.add({
        severity: "success",
        summary: "Successful",
        detail: "Products Deleted",
        life: 3000,
      });
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>

<style scoped lang="scss">
@import "../assets/demo/badges.scss";
</style>
